@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600&display=swap');

:root {
  --background: #ffffff;
  --dark: #051d40;
  --mid: #145da0;
  --light: #56aeff;
  --extraLight: #b1d4e0;
  --grey: #eeeeee;
  --font: 'Source Sans Pro', sans-serif;
}

* {
  box-sizing: border-box;
}

body {
  background: var(--background);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: var(--font);
}

#hero {
  width: 100vw;
}
#hero-text {
  font-size: 50px; 
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -130%);
  color: white
}


.repair {
  width:87vw;
  border-radius: 6px;
  margin-bottom: 32px;
  margin: 32 auto;
}

.container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center; 

}

.justify {
  width: 87vw;
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 32px;
}

.landlordContainer{
  width:97vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.generalRepairs {
  color: var(--background);
  background-color: rgb(173, 171, 171);
  color: #fdfbfb;
  background-repeat: no-repeat;

  width: 95vw;
  padding-top: 20px;;
  display:flex;
  box-shadow: 0 0 8px grey;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  border-radius: 6px;
}

.landlord {
  width: 60vw;
}
.main {
  width:100vw;
  margin:auto;
  margin-bottom: 16px;
}

.service {
  margin:16px;
  width: 300px;
  height: 300px;
  box-shadow: 0 0 8px rgb(167, 166, 166);
  border-radius: 6px;
  
}

.service-containers {
  width:97vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
.services {
  width:95vw;
  margin-top: 30px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  /* box-shadow: 0 0 8px rgb(167, 166, 166); */
  border-radius: 6px;
  margin-top: 40px;
  background-color: rgb(173, 171, 171);
  color: white;
}

.serviceImg {
  width: 100%;
  height:230px;
  border-radius: 0 0 6px 6px;
}

.nav {
  margin-top:-20px;
  background-color: black;
  color: white;
  
}
.navList {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.icon {
  height: 15px;
  margin: 10px;
}

/* mobile nav */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  height: 100vh;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: white;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 600px) {

    #hero-text {
  font-size: 100px;
 
    }
    .hero-text {

      transform: translate(-50%, -30%);

    }

    .subheader{
      font-size: 30px;
    }
}
